<template>
  <div id="member_index">
    <div class="user_info">
      <div class="block">
        <div class="title">我的账户</div>
        <div class="item">
          <div class="pc_icon">
            <img src="@/assets/img/auer_mobile.png" alt="" />
          </div>
          <div class="lev">商家ID：</div>
          <div class="lev_name">{{ $store.state.userInfo.info.id }}</div>
        </div>
        <div class="item">
          <div class="pc_icon">
            <img src="@/assets/img/auser_vip.png" alt="" />
          </div>
          <div class="lev">等&nbsp;&nbsp;&nbsp;级：</div>
          <div class="lev_name">{{ $store.state.userInfo.level }}</div>
          <div class="add_lev">
            <router-link to="/member/vip">[升级VIP]</router-link>
          </div>
        </div>
        <div class="item">
          <div class="pc_icon">
            <img src="@/assets/img/auser_phone.png" alt="" />
          </div>
          <div class="lev">手机号：</div>
          <div class="lev_name" v-if="$store.state.userInfo.info">
            {{ $store.state.userInfo.info.phone }}
          </div>
        </div>
        <!-- <div class="item">
                    <div class="pc_icon">
                        <img src="@/assets/img/auser_email.png" alt="">
                    </div>
                    <div class="lev">邮&nbsp;&nbsp;&nbsp;箱：</div>
                    <div class="lev_name">{{$store.state.userInfo.info.email}}</div>
                </div>
                <div class="item">
                    <div class="pc_icon">
                        <img src="@/assets/img/auer_regtime.png" alt="">
                    </div>
                    <div class="lev">注&nbsp;&nbsp;&nbsp;册：</div>
                    <div class="lev_name" v-if="$store.state.userInfo.info">{{$time.getDate($store.state.userInfo.info.createTime)}}</div>
                </div> -->
        <div class="item">
          <div class="pc_icon">
            <img src="@/assets/img/auer_rmb.png" alt="" />
          </div>
          <div class="lev">余&nbsp;&nbsp;&nbsp;额：</div>
          <div class="lev_name">
            {{ $store.state.userInfo.info.balance }} 元<span
              ></span
            >
          </div>
        </div>
      </div>
      <div class="block">
        <div class="title">联系客服</div>
        <!-- <div class="item">
                    <div class="pc_icon">
                        <img src="@/assets/img/auer_nickname.png" alt="">
                    </div>
                    <div class="lev">工&nbsp;&nbsp;&nbsp;号：</div>
                    <div class="lev_name">1006</div>
                </div> -->
        <!-- <div class="item">
                    <div class="pc_icon">
                        <img src="@/assets/img/auer_mobile.png" alt="">
                    </div>
                    <div class="lev">姓&nbsp;&nbsp;&nbsp;名：</div>
                    <div class="lev_name">{{$store.state.tenantInfo.company}}--欢欢</div>
                </div> -->
        <div class="item">
          <div class="pc_icon">
            <img src="@/assets/img/icon_qq.png" alt="" />
          </div>
          <div class="lev">Q&nbsp;&nbsp;&nbsp;&nbsp;Q：</div>
          <div class="lev_name">{{ $store.state.tenantInfo.qq }}</div>
        </div>
        <div class="item">
          <div class="pc_icon">
            <img src="@/assets/img/icon_wechat.png" alt="" />
          </div>
          <div class="lev">微&nbsp;&nbsp;&nbsp;信：</div>
          <div class="lev_name">请从右侧 扫码加微信</div>
        </div>
        <div class="item">
          <div class="pc_icon">
            <img src="@/assets/img/auser_phone.png" alt="" />
          </div>
          <div class="lev">电&nbsp;&nbsp;&nbsp;话：</div>
          <div class="lev_name">{{ $store.state.tenantInfo.phone }}</div>
        </div>
      </div>
      <div class="qrcode">
        <!-- <img :src="$store.state.tenantInfo.wechat | fullPath" alt=""> -->
        <el-image
          :src="$store.state.tenantInfo.wechat | fullPath"
          :preview-src-list="
            $common.getImgUrl($store.state.tenantInfo.wechat) .split(',')
          "
          style="width: 120px; height: 120px"
          @click.stop="handleClickItem"
        >
        </el-image>
        <p>请扫码，加微信</p>
      </div>
    </div>
    <div class="liuc">
      <div class="item" @click="$router.push('/member/send_order')">
        <div class="item_in">
          <img src="@/assets/img/fh.png" alt="" />
          <p>立即发货</p>
        </div>
      </div>
      <div class="item" @click="$router.push('/member/order')">
        <div class="item_in">
          <img src="@/assets/img/dd.png" alt="" />
          <p>订单管理</p>
        </div>
      </div>
      <div class="item" @click="$router.push('/member/check_pieces')">
        <div class="item_in">
          <img src="@/assets/img/sh.png" alt="" />
          <p>售后管理</p>
        </div>
      </div>
      <div class="item" @click="$router.push('/member/receive_order')">
        <div class="item_in">
          <img src="@/assets/img/didan.png" alt="" />
          <p>底单申请</p>
        </div>
      </div>
    </div>
    <div class="user_info clearfix" style="display: block">
      <div class="block">
        <div class="title">订单管理</div>
      </div>
      <div class="buy_step">
        <ul>
          <li class="fl">
            <router-link to="/member/order?status=0">
              <div class="pic">
                <img src="@/assets/img/auser_put1.png" alt="" />
              </div>
              <p>待付款</p>
              <p class="red">{{ order.waitPay }}</p>
            </router-link>
          </li>
          <li class="fl">
            <router-link to="/member/order?status=1">
              <div class="pic">
                <img src="@/assets/img/auser_put2.png" alt="" />
              </div>
              <p>任务处理中</p>
              <p class="bak">{{ order.dispose }}</p>
            </router-link>
          </li>
          <li class="fl">
            <router-link to="/member/order?status=2">
              <div class="pic">
                <img src="@/assets/img/auser_put3.png" alt="" />
              </div>
              <p>可发货</p>
              <p class="red">{{ order.butTheDelivery }}</p>
            </router-link>
          </li>
          <li class="fl">
            <router-link to="/member/order?status=3">
              <div class="pic">
                <img src="@/assets/img/auser_put4.png" alt="" />
              </div>
              <p>发货中</p>
              <p class="bak">{{ order.shipping }}</p>
            </router-link>
          </li>
          <li class="fl">
            <router-link to="/member/order?status=4">
              <div class="pic">
                <img src="@/assets/img/auser_put5.png" alt="" />
              </div>
              <p>发货完成</p>
              <p class="bak">{{ order.finish }}</p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="user_info" style="margin-top: 15px; min-height: 240px">
      <div class="block">
        <div class="title">常见问题</div>
        <div class="news_list">
          <ul>
            <li v-for="item in articleList2">
              <!-- <router-link :to="'/news/'+item.id+'?type='+item.type" target="_blank">
                                <span>{{$moment(item.createTime).format('Y-MM-DD HH:mm')}}</span>
                                <div class="item">{{item.title}}</div>
                            </router-link> -->
              <div @click="showDialog(item)" style="cursor: pointer">
                <div class="item">{{ item.title }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="block">
        <div class="title">最新公告</div>
        <div class="news_list2">
          <ul>
            <li v-for="item in articleList">
              <!-- <router-link :to="'/news/'+item.id+'?type='+item.type" target="_blank">
                                <span>{{$moment(item.createTime).format('Y-MM-DD HH:mm')}}</span>
                                <div class="item">{{item.title}}</div>
                            </router-link> -->
              <div @click="showDialog(item)" style="cursor: pointer">
                <span>{{
                  $moment(item.createTime).format("Y-MM-DD HH:mm")
                }}</span>
                <div class="item2">通知：{{ item.title }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <el-dialog
      :title="article.type == 1 ? '系统公告' : '常见问题'"
      :visible.sync="dialogVisible"
      width="800px"
      top="7vh"
      center
    >
      <div class="el-dialog-div">
        <div class="articleTitle">{{ article.title }}</div>
        <div class="other">
          <div>
            来源：{{
              $store.state.tenantInfo.company
            }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <div>
            时间：{{
              $moment(article.createTime).format("Y-MM-DD HH:mm")
            }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <div>
            浏览量：{{ article.viewNum }} 次&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </div>
        <div class="line"></div>
        <div class="detail" v-html="article.content"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="danger"
          style="margin-top: 30px"
          @click="toUrl('/news/' + article.id + '?type=' + article.type)"
          >查看详情</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TimeUtil from "@/utils/time_utils";
export default {
  name: "",
  data() {
    return {
      id:"",
      order: {
        waitPay: 0,
        dispose: 0,
        butTheDelivery: 0,
        shipping: 0,
        finish: 0,
      },
      articleList: [],
      articleList2: [],
      dialogVisible: false,
      article: {},
    };
  },
  created() {
    
    this.getOrderCount();
    this.getArticleList();
    this.getArticleList2();

    
  },
  mounted() {},
  watch: {},
  methods: {
   
    handleClickItem() {
      this.$nextTick(() => {
        // 获取遮罩层dom
        let domImageMask = document.querySelector(".el-image-viewer__mask");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", () => {
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });
      });
    },
    //获取订单信息处理个数
    getOrderCount() {
         this.id = localStorage.getItem('ids');
      console.log(this.id)
      
      this.$request.post({
        // url:'/bus/order/data',
        url: "/ReportApi/business/ordercount",
        params: {
          id:this.id,
          },
        success: (res) => {
          this.order = res;
        },
      });
    },
    //获取最新公告列表
    getArticleList() {
    
      var isConent = 1;
      var ress = [];
      this.$request.post({
        url: "/ReportApi/business/articlelist",
        params: {
          type: 1,
        },
        success: (res) => {
          //consloe.log(res);
          for (let i = 0; i <res.length; i++) {
            if (isConent != 1) {
            
              res[i].createTime = res[i].create_time;
              delete res[i].create_time;
              res[i].viewNum = res[i].view_num;
              delete res[i].view_num;
            } else {
           console.log(res[i])
           
              res[i].createTime = res[i].create_time;
              delete res[i].create_time;
              res[i].viewNum = res[i].view_num;
              delete res[i].view_num;
            }
            ress.push(res[i]);
          }
          //consloe.log(ress);
          this.articleList = ress;
        },
      });
    },
    //获取常见问题列表
    getArticleList2() {
      this.$request.post({
        url: "/ReportApi/business/articlelist",
        params: {
          // pageNo:1,
          // pageSize:5,
          type: 4,
          // isConent:1
        },
        success: (res) => {
          //consloe.log(res);
          for (let i = 0; i < res.length; i++) {
            delete res[i].keywords;
            delete res[i].remark;
            delete res[i].tenant_id;
            res[i].createTime = res[i].create_time;
            delete res[i].create_time;
            res[i].viewNum = res[i].view_num;
            delete res[i].view_num;
          }
          this.articleList2 = res;
        },
      });
    },
    toUrl(url) {
      if (url.indexOf("http") != -1) {
        window.open(url);
      } else {
        this.$router.push(url);
      }
    },
    showDialog(item) {
      this.article = item;
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss">
.detail {
  img {
    width: 100%;
  }
}
.el-dialog-div {
  height: 65vh;
  overflow: auto;
}
</style>
<style lang="scss" scoped>
#member_index {
  font-size: 14px;
  .articleTitle {
    text-align: center;
    color: #f80;
    font-weight: bold;
    margin-top: 30px;
    font-size: 18px;
  }
  .other {
    display: flex;
    width: 485px;
    margin: 0 auto;
    font-size: 14px;
    color: #666;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .line {
    border-bottom: 1px dashed #999;
    margin-bottom: 20px;
  }
  .user_info {
    background: #fff;
    border-radius: 4px;
    padding-top: 18px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 20px;
    width: 1020px;
    box-sizing: border-box;
    display: flex;
    position: relative;
    .block {
      flex: 1;
    }
    .title {
      border-left: 5px solid #888888;
      color: #333;
      font-weight: bold;
      padding-left: 12px;
      margin-bottom: 10px;
    }
    .item {
      width: 100%;
      display: inline-block;
      color: #333;
      div {
        float: left;
        margin-right: 20px;
        line-height: 40px;
        a {
          color: #f80;
        }
      }
      .pc_icon {
        line-height: normal;
        margin-right: 10px;
        margin-left: 20px;
        img {
          margin-top: 14px;
        }
      }
    }
    .qrcode {
      width: 120px;
      position: absolute;
      right: 60px;
      top: 40px;
      color: #999;
      text-align: center;
      img {
        width: 120px;
        height: 120px;
      }
    }
    .buy_step {
      ul {
        margin-top: 20px;
        li {
          width: 190px;
          text-align: center;
          border-right: 1px solid #eee;
          line-height: 20px;
          .red {
            color: #f80;
            font-size: 22px;
            font-weight: bold;
          }
          .bak {
            font-size: 22px;
            font-weight: bold;
          }
        }
      }
    }
    .news_list {
      ul {
        li {
          margin-left: 25px;
          line-height: 40px;
          text-overflow: ellipsis;
          white-space: nowrap;
          list-style-type: decimal;
          span {
            float: right;
            margin-right: 70px;
          }
          .item {
            width: 256px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            float: left;
          }
        }
      }
    }
    .news_list2 {
      ul {
        li {
          margin-left: 25px;
          line-height: 40px;
          text-overflow: ellipsis;
          white-space: nowrap;
          list-style-type: none;
          span {
            float: right;
            margin-right: 70px;
          }
          .item {
            width: 256px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            float: left;
          }
          .item2 {
            width: 256px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            float: left;
            color: #f80;
            font-weight: 700;
          }
        }
      }
    }
  }
  .liuc {
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f80;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 15px 0;
    color: #fff;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 22px 0;
      flex: 1;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        background: #ff7600;
      }
      .item_in {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba(255, 255, 255, 0.65);
        box-sizing: border-box;
        img {
          width: 25px;
          height: 25px;
        }
        p {
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.45);
          font-weight: bold;
        }
      }
    }
  }
}
</style>